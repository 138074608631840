const Utility = {
  getTime: () => {
    const date = new Date();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  },
  generateUniqueId: () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  },
};

export default Utility;
