import { useEffect, useState, useRef } from "react";
import ChatService from "../../front-services/ChatService";
import Colors from "../../utils/Colors";
import ReactMarkdown from "react-markdown";
import WideImageViewer from "./components/WideImageViewer";
import constants from "../../utils/Constants";
import HandoffForm from "./components/Handoff/HandoffForm";
import HandoffButton from "./components/Handoff/HandoffButton";
import MessageHandler from "./logic/MessageHandler";
import Utility from "./logic/Utility";
import ListViewWithQuoteTemplate from "./components/slider/templates/ListViewWithQuoteTemplate";
import ChatTooltip from "./components/ChatTooltip";
import ChatCache from "../../utils/ChatCache";

export interface ChatWidgetStyle {
  color: string;
  name: string;
  fileUrl: string;
  loaded: boolean;
}

const chatService = new ChatService();
const messageHandler = new MessageHandler();
const chatCache = new ChatCache();

const mapTool = {
  hotel_info: "Cercando informazioni sull'hotel...",
  generate_quote: "Preparando un preventivo...",
  check_availability: "Controllo disponibilità...",
  print_calendar: "Controllo calendario...",
  nearby_restaurants: "Cercando ristoranti nelle vicinanze...",
  nearby_attractions: "Cercando attrazioni nelle vicinanze...",
  human_handoff: "Collegamento con un operatore...",
};

const Chat = ({
  startOpen = false,
  isMobile = false,
  isEditor = false,
  editorStyle = null,
  assistant,
}: any) => {
  const [isOpen, setIsOpen] = useState(isEditor || startOpen);
  const [messages, setMessages]: any = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [threadId, setThreadId]: any = useState(null);
  const [toolName, setToolName] = useState(null);
  const [welcomeMessageTime] = useState(Utility.getTime());
  const [showHandoffButton, setShowHandoffButton] = useState(true);

  const [isWIVFullScreen, setWIVIsFullScreen] = useState(false);
  const [WIVfullScreenImage, setWIVFullScreenImage] = useState<any>(null);

  const [isHandoffFormFullScreen, setIsHandoffFormFullScreen] = useState(false);
  const [isAnyFullScreen, setIsAnyFullScreen] = useState(false);
  const [sliderContent, setSliderContent] = useState<any>(null);
  const [ready, setReady] = useState(false);

  const [style, setStyle] = useState<ChatWidgetStyle>({
    color: editorStyle ? editorStyle.mainColor : "#a72eff",
    name: "Nome Assistente",
    welcomeMessage: null,
    fileUrl: "https://plutoniosolutions.com/assets/img/SmallCircle.webp",
    ...editorStyle,
  });

  const [assistantChatConfiguration, setAssistantChatConfiguration]: any =
    useState({ loaded: false });

  const chatMessagesRef: any = useRef(null);
  const chatContainerRef: any = useRef(null);

  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const toggleCardVisibility = () => {
    setIsSliderVisible(!isSliderVisible);
  };
  const mobileDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsAnyFullScreen(isWIVFullScreen || isHandoffFormFullScreen);
  }, [isHandoffFormFullScreen, isWIVFullScreen]);

  // [ALL] Load the assistant and chat configuration based on the URL parameters
  useEffect(() => {
    if (assistant.id) {
      setMessages(chatCache.getMessages(assistant.id));
      setThreadId(chatCache.getThreadId(assistant.id));
      chatService
        .getAssistantChatConfiguration(assistant.id)
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              setAssistantChatConfiguration({ ...data, loaded: true });
            });
          }
        });

      chatService.getWidgetConfiguration(assistant.id).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const resStyle = data.configuration.style;

            document.documentElement.style.setProperty(
              "--primary-color",
              resStyle.mainColor
            );

            setStyle({
              color: resStyle.mainColor,
              name: resStyle.name,
              fileUrl: resStyle.fileUrl,
              loaded: true,
            });
          });
        }
      });
    }
  }, [assistant]);

  // [ALL] Scroll to the bottom of the chat messages when a new message is added
  useEffect(() => {
    chatMessagesRef.current?.scrollTo(0, chatMessagesRef.current.scrollHeight);
  }, [messages]);

  const useEffectOnRef = (ref: any, callback: any) => {
    const previousRef = useRef();

    useEffect(() => {
      if (ref.current !== previousRef.current) {
        callback();
        previousRef.current = ref.current;
      }
    }, [ref, callback]);
  };

  useEffectOnRef(chatMessagesRef, () => {
    chatMessagesRef.current?.scrollTo(0, chatMessagesRef.current.scrollHeight);
  });

  // [MOBILE] Adjust the chat footer position when the keyboard is opened
  useEffect(() => {
    const adjustForKeyboard = () => {
      const viewportHeight = window.innerHeight;
      const widgetFooter = document.querySelector(
        ".widget-chat-footer"
      ) as HTMLElement;
      if (widgetFooter) {
        widgetFooter.style.bottom = `${window.outerHeight - viewportHeight}px`; // Adjust based on remaining height
      }
    };

    window.addEventListener("resize", adjustForKeyboard);

    return () => {
      window.removeEventListener("resize", adjustForKeyboard);
    };
  }, []);

  useEffect(() => {
    chatService.ping().then((response) => {
      if (response.ok) setReady(true);
    });
  }, []);

  const toggleChatWidget = () => {
    if (isOpen) {
      closeSlider();

      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleInputChange = (event: any) => {
    setInputMessage(event.target.value);
  };

  const handleUserMessage = async () => {
    if (inputMessage.trim() === "") return;

    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "user", content: inputMessage, time: Utility.getTime() },
    ]);

    chatCache.addMessage(
      { type: "user", content: inputMessage, time: Utility.getTime() },
      threadId,
      assistant.id
    );

    setInputMessage("");

    setIsMessageLoading(true);

    if (assistantChatConfiguration.isStreamEnabled) {
      await messageHandler.handleUserMessageWithStream(
        inputMessage,
        setMessages,
        setIsMessageLoading,
        setThreadId,
        assistant,
        threadId,
        setToolName,
        openSlider
      );
    } else {
      await messageHandler.handleUserMessageWithoutStream(
        setIsMessageLoading,
        assistant,
        threadId,
        setThreadId,
        inputMessage,
        setMessages
      );
    }
  };

  const handleShortcutClick = async (userMessage: any) => {
    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "user", content: userMessage, time: Utility.getTime() },
    ]);
    chatCache.addMessage(
      { type: "user", content: userMessage, time: Utility.getTime() },
      threadId,
      assistant.id
    );
    setInputMessage("");
    setIsMessageLoading(true);

    if (assistantChatConfiguration.isStreamEnabled) {
      await messageHandler.handleUserMessageWithStream(
        userMessage,
        setMessages,
        setIsMessageLoading,
        setThreadId,
        assistant,
        threadId,
        setToolName,
        openSlider
      );
    } else {
      await messageHandler.handleUserMessageWithoutStream(
        setIsMessageLoading,
        assistant,
        threadId,
        setThreadId,
        userMessage,
        setMessages
      );
    }
  };

  const closeSlider = () => {
    setIsSliderVisible(() => {
      return false;
    });
  };

  const openSlider = (templates: any) => {
    if (!constants.FEATURE_FLAGS.SLIDER) return;

    const template = templates[0];

    switch (template.type) {
      case "list_view_with_quote":
        setSliderContent(
          <ListViewWithQuoteTemplate
            template={template}
            closeSlider={closeSlider}
          />
        );
        break;
    }

    setIsSliderVisible(true);
  };

  const CustomLinkRenderer = ({ href, children }: any) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  useEffect(() => {
    const handleWidgetMessage = (event: any) => {
      const message = event.detail;

      if (message.color) {
        setStyle(message);
        document.documentElement.style.setProperty(
          "--primary-color",
          message.color
        );
      }

      if (message.text) {
        console.log("Message text:", message.text);
      }
    };

    const shadowHost = document.getElementById("widget-editor");
    if (shadowHost && shadowHost.shadowRoot) {
      shadowHost.shadowRoot.addEventListener(
        "widgetMessage",
        handleWidgetMessage
      );
    }

    return () => {
      if (shadowHost && shadowHost.shadowRoot) {
        shadowHost.shadowRoot.removeEventListener(
          "widgetMessage",
          handleWidgetMessage
        );
      }
    };
  }, []);

  const cleanCache = () => {
    setMessages([]);
    setThreadId(null);
    chatCache.remove(assistant.id);
  };

  return (
    <>
      {!isEditor &&
        style.loaded &&
        ready &&
        assistantChatConfiguration.loaded && (
          <div
            className="widget-chat-button p-1 w-14 h-14 rounded-full shadow-lg flex items-center justify-center text-white text-3xl focus:outline-none hover:bg-blue-600 transition-colors duration-200 ease-in-out relative"
            style={{
              backgroundColor: style.color,
              boxShadow: `0 4px 6px -1px ${style.color}, 0 2px 4px -1px ${style.color}`,
              opacity: !isOpen ? "1" : "0",
              transition: "opacity 0.15s ease-in-out",
              position: "fixed",
              bottom: isMobile ? "20px" : "50px",
              right: isMobile ? "20px" : "50px",
              width: isMobile ? "60px" : "80px",
              height: isMobile ? "60px" : "80px",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              textAlign: "center",
              lineHeight: "60px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={!isEditor ? toggleChatWidget : undefined}
          >
            <i
              className="fas fa-comments chat-icon"
              style={{ fontSize: isMobile ? "25px" : "38px" }}
            ></i>
            {constants.FEATURE_FLAGS.TOOLTIP && (
              <ChatTooltip isMobile={isMobile} />
            )}

            {/* Pulse Effect */}
            <div
              className="pulse-effect"
              style={{
                position: "absolute",
                bottom: "0px", // Adjust as needed
                left: "0px",
                width: isMobile ? "12px" : "15px",
                height: isMobile ? "12px" : "15px",
                borderRadius: "50%",
                backgroundColor: "rgba(38, 178, 11)", // Use RGBA for background
                border: "2px solid white",
                boxShadow: "0 0 0 0 rgba(38, 178, 11, 0.4)",
                animation: "pulse 2s infinite",
              }}
            ></div>
          </div>
        )}

      <div
        style={{
          position: "fixed",
          right: !isSliderVisible ? "20px" : "420px",
          width: constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING,
          bottom: isMobile ? "0px" : "20px",
          height: constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING,
          maxHeight: isOpen
            ? isMobile
              ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
              : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING
            : "0",
          border: `1px solid ${style.color}`,
          boxShadow: `0 2px 6px -1px ${style.color}, 0 2px 2px -1px ${style.color}`,
          padding: "0px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          borderRadius: "30px 0px 0px 30px",
          overflow: "hidden",
          opacity: isSliderVisible ? "1" : "0",
          transition:
            "right 0.4s ease-in-out, max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, border 0.4s ease-in-out",
        }}
      >
        {sliderContent && sliderContent}
      </div>

      {isMobile && (
        <div
          ref={mobileDivRef}
          style={{
            position: "fixed",
            bottom: isSliderVisible ? "0%" : "-70%",
            left: "0",
            width: "100%",
            height: "70%",
            backgroundColor: "#fff",
            boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.2)",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            transition: "bottom 0.4s ease-in-out, opacity 0.4s ease-in-out",
            opacity: isSliderVisible ? "1" : "0",
            zIndex: "1000",
          }}
        >
          <div
            style={{
              height: "100%",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              overflowY: "auto",
            }}
          >
            {sliderContent && sliderContent}
          </div>
        </div>
      )}
      {isMobile && isSliderVisible && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "999",
          }}
          onClick={() => toggleCardVisibility()}
        />
      )}

      <div
        ref={chatContainerRef}
        style={{
          position: isEditor ? "unset" : "fixed",
          right: isEditor ? "unset" : isMobile ? "0px" : "20px",
          width: isMobile
            ? constants.DIMENSIONS.MOBILE.OPEN.WIDTH_STRING
            : constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING,
          bottom: isEditor ? "unset" : isMobile ? "0px" : "20px",
          height: isMobile
            ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
            : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING,
          maxHeight: isOpen // When it's open, use the appropriate max-height for open state
            ? isMobile
              ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING // Mobile open state
              : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING // Desktop open state
            : "0",
          border: `1px solid ${style.color}`,
          boxShadow: `0 2px 6px -1px ${style.color}, 0 2px 2px -1px ${style.color}`,
          padding: "0px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          borderRadius: isMobile
            ? "0px"
            : isSliderVisible
            ? "0px 30px 30px 0px"
            : "30px",
          overflow: "hidden",
          opacity: isOpen ? "1" : "0",
          transition:
            "max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, border 0.4s ease-in-out", // Use 'ease-in-out' for smoother transition
        }}
      >
        {isWIVFullScreen && (
          <WideImageViewer
            image={WIVfullScreenImage}
            maxHeight={
              isMobile
                ? undefined
                : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING
            }
            maxWidth={
              isMobile
                ? undefined
                : constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING
            }
            setFullScreen={setWIVIsFullScreen}
            isFullScreen={isWIVFullScreen}
            setFullScreenImage={setWIVFullScreenImage}
          />
        )}

        {isHandoffFormFullScreen && (
          <HandoffForm
            color={style.color}
            threadId={threadId}
            assistantId={assistant.id}
            setMessages={setMessages}
            setIsHandoffFormFullScreen={setIsHandoffFormFullScreen}
            setShowButton={setShowHandoffButton}
          />
        )}

        {!isAnyFullScreen && (
          <>
            {" "}
            <div
              className="widget-header"
              style={{ backgroundColor: style.color, borderColor: style.color }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={style.fileUrl}
                  style={{ borderRadius: "50%" }}
                  alt="Assistant Icon"
                  className="header-icon inline-block"
                />
                <div className="header-title-description">
                  <span className="text-lg font-semibold ml-2 widget-header-title">
                    <b>{style.name}</b>
                  </span>
                  <span className="header-description">
                    Replies in real-time
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="trash-button"
                  style={{
                    backgroundColor: Colors.lightenHexColor(style.color, 35),
                  }}
                  onClick={cleanCache}
                  disabled={isEditor}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>

                <button
                  className="minimize-button"
                  style={{
                    backgroundColor: Colors.lightenHexColor(style.color, 35),
                  }}
                  onClick={toggleChatWidget}
                  disabled={isEditor}
                >
                  <i className="fa-solid fa-x"></i>
                </button>
              </div>
            </div>
            <div className="widget-chat-messages" ref={chatMessagesRef}>
              {assistantChatConfiguration &&
                assistantChatConfiguration.welcomeMessage && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        marginBottom: "10px",
                        backgroundColor: Colors.lightenHexColor(
                          style.color,
                          60
                        ),
                      }}
                      className="widget-chatbot-message"
                    >
                      <ReactMarkdown>
                        {assistantChatConfiguration.welcomeMessage}
                      </ReactMarkdown>
                      {assistantChatConfiguration.shortcutButtons && (
                        <div className="shortcut-buttons">
                          {assistantChatConfiguration.shortcutButtons.map(
                            (sbutton: any, index: any) => (
                              <button
                                className="shortcut-button"
                                style={{
                                  color: Colors.lightenHexColor(
                                    style.color,
                                    10
                                  ),
                                }}
                                key={index}
                                onClick={() =>
                                  handleShortcutClick(sbutton.userMessage)
                                }
                              >
                                {sbutton.label}
                              </button>
                            )
                          )}
                        </div>
                      )}
                      <p className="message-time">{welcomeMessageTime}</p>
                    </div>
                  </div>
                )}
              {messages.map((message: any, index: any) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    backgroundColor:
                      message.type !== "user"
                        ? Colors.lightenHexColor(style.color, 60)
                        : Colors.lightenHexColor(style.color, 85),
                  }}
                  className={
                    message.type === "user"
                      ? "widget-user-message"
                      : "widget-chatbot-message"
                  }
                >
                  <ReactMarkdown
                    components={{
                      a: CustomLinkRenderer, // Override how links are rendered
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                  {message.metadata &&
                    message.metadata.map((meta: any, index: any) => {
                      switch (meta.type) {
                        case "image": {
                          if (meta.value.startsWith("https://i.ibb.co/")) {
                            return (
                              <div className="message-attachment">
                                <WideImageViewer
                                  image={meta.value}
                                  maxHeight={
                                    isMobile
                                      ? constants.DIMENSIONS.MOBILE.OPEN
                                          .HEIGHT_STRING
                                      : constants.DIMENSIONS.DESKTOP.OPEN
                                          .HEIGHT_STRING
                                  }
                                  maxWidth={
                                    isMobile
                                      ? constants.DIMENSIONS.MOBILE.OPEN
                                          .WIDTH_STRING
                                      : constants.DIMENSIONS.DESKTOP.OPEN
                                          .WIDTH_STRING
                                  }
                                  setFullScreen={setWIVIsFullScreen}
                                  isFullScreen={isWIVFullScreen}
                                  setFullScreenImage={setWIVFullScreenImage}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <img
                                key={index}
                                src={meta.value}
                                alt="immagine"
                                className="message-attachment"
                              />
                            );
                          }
                        }
                        case "human_handoff_form": {
                          return (
                            <HandoffButton
                              setHandoffFormFullscreen={
                                setIsHandoffFormFullScreen
                              }
                              show={showHandoffButton}
                              label={"Parla con un operatore"}
                              style={style}
                            />
                          );
                        }
                      }

                      return <></>;
                    })}
                  <p className="message-time">{message.time}</p>
                </div>
              ))}
              {isMessageLoading && (
                <div
                  className="widget-chatbot-message"
                  style={{
                    backgroundColor: Colors.lightenHexColor(style.color, 60),
                  }}
                >
                  <span
                    className="loader-container"
                    style={{
                      backgroundColor: Colors.lightenHexColor(style.color, 60),
                    }}
                  >
                    {toolName && mapTool[toolName]}
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </span>
                </div>
              )}
            </div>
            <div
              className="widget-chat-footer"
              style={{
                backgroundColor: style.color,
              }}
            >
              <div className="widget-chat-input-container border border-grey-400">
                <textarea
                  onClick={() => {
                    if (isMobile)
                      chatContainerRef.current?.scrollTo(
                        0,
                        chatContainerRef.current.scrollHeight
                      );
                  }}
                  className="widget-chat-input"
                  disabled={isEditor}
                  style={{ fontFamily: "roboto, sans-serif", fontSize: "15px" }}
                  placeholder="Scrivi un messaggio..."
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleUserMessage();
                    }
                  }}
                />
                <button
                  disabled={isEditor || isMessageLoading}
                  className="widget-send-button flex-none m-0 p-0 text-white"
                  style={{ backgroundColor: style.color }}
                  onClick={handleUserMessage}
                >
                  <i
                    className="fa-regular fa-paper-plane"
                    style={{ color: "whitesmoke", fontSize: "16px" }}
                  ></i>
                </button>
              </div>
              <div className="powered-by">
                <span
                  style={{
                    fontSize: "10px",
                    color: "white",
                    marginRight: "3px",
                  }}
                >
                  Powered By
                </span>
                <a
                  href="https://plutoniosolutions.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontSize: "12px",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  PlutoniosSolutions
                  <img
                    src="https://plutoniosolutions.com/assets/img/SmallCircle.webp"
                    alt="Plutonios Solutions Logo"
                    className="inline-block mr-1"
                    style={{
                      width: "10px",
                      marginLeft: "2px",
                      verticalAlign: "baseline",
                      border: "1px solid white",
                      borderRadius: "50%",
                    }}
                  />
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Chat;
