import React, { useState, useEffect, useRef } from "react";

const sentences: string[] = [
  "Vuoi un preventivo veloce? \u{1F60A}",
  "Vuoi informazioni sull'hotel? \u{1F60A}",
];

const typingSpeed: number = 80;
const pauseDuration: number = 2500;

export default function ChatTooltip({ isMobile }: any) {
  const [displayedText, setDisplayedText] = useState<string>("");
  const sentenceIndexRef = useRef<number>(0);

  function* typingGenerator(sentence: string): Generator<string> {
    let text = "";
    const characters = Array.from(sentence); // Handles emojis as single entities
    for (const char of characters) {
      text += char;
      yield text;
    }
    yield "PAUSE";
  }

  useEffect(() => {
    let isMounted = true;

    const runTypingEffect = async () => {
      while (isMounted) {
        const sentence = sentences[sentenceIndexRef.current];
        setDisplayedText("");
        const generator = typingGenerator(sentence);

        for (const partialText of generator) {
          if (partialText === "PAUSE") {
            await new Promise((resolve) => setTimeout(resolve, pauseDuration));
            break;
          }
          setDisplayedText(partialText);
          await new Promise((resolve) => setTimeout(resolve, typingSpeed));
        }

        sentenceIndexRef.current =
          (sentenceIndexRef.current + 1) % sentences.length;
      }
    };

    runTypingEffect();

    return () => {
      isMounted = false;
    };
  }, []);

  return isMobile ? (
    <div className="chat-tooltip-mobile">
      <div className="chat-tooltip-text-mobile">{displayedText}</div>
    </div>
  ) : (
    <div className="chat-tooltip-desktop">
      <div className="chat-tooltip-text-desktop">{displayedText}</div>
    </div>
  );
}
