import { useEffect } from "react";

const INTERVAL_TIME = 10000;

export default function ImageSlideShow({
  images,
  currentIndex,
  setCurrentIndex,
}: any) {
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex: any) => (prevIndex + 1) % images.length);
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [images.length, setCurrentIndex]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex: any) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex: any) => (prevIndex + 1) % images.length);
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };
  return (
    <>
      {/* Image */}
      {images.map((image: any, index: any) => (
        <img
          key={index}
          src={image.image_url}
          alt={image.description}
          className="image-slideshow-item"
          style={{
            opacity: index === currentIndex ? 1 : 0,
            transition: "opacity 0.8s ease-in-out",
          }}
        />
      ))}

      {/* Navigation buttons */}
      <button
        onClick={goToPrevious}
        className="image-slideshow-navigation-button"
        style={{
          left: "5px",
        }}
      >
        ❮
      </button>
      <button
        onClick={goToNext}
        className="image-slideshow-navigation-button"
        style={{
          right: "5px",
        }}
      >
        ❯
      </button>

      {/* Dots navigation */}
      <div className="image-slideshow-dot-container">
        {images.map((_: any, index: any) => (
          <div
            key={index}
            onClick={() => goToSlide(index)}
            className="image-slideshow-dot"
            style={{
              backgroundColor:
                index === currentIndex ? "var(--primary-color)" : "#fff",
              opacity: index === currentIndex ? 1 : 0.5,
            }}
          />
        ))}
      </div>
    </>
  );
}
