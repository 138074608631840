import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

// Expose the `init` function globally
declare global {
  interface Window {
    ChatWidget: {
      init: (options: {
        container: HTMLElement;
        a_id?: string;
        a_name?: string;
        isEditor?: boolean;
        editorStyle?: any;
      }) => void;
    };
  }
}

function getUrlParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    a_id: params.get("a_id") || undefined,
    a_name: params.get("a_name") || undefined,
    isLocal: params.get("isLocal") === "true" || false,
  };
}

function renderApp(
  container: HTMLElement,
  a_id?: string,
  a_name?: string,
  isEditor?: boolean,
  editorStyle?: any
) {
  if (!container) {
    console.error("ChatWidget: Container is undefined or null.");
    return;
  }

  console.log("Initializing ChatWidget in container:", container);

  ReactDOM.createRoot(container).render(
    <BrowserRouter>
      <App
        assistant={{ id: a_id, name: a_name }}
        isEditor={isEditor}
        editorStyle={editorStyle}
      />
    </BrowserRouter>
  );
}

// Attach `init` function to the window object
window.ChatWidget = {
  init: ({ container, a_id, a_name, isEditor, editorStyle }) => {
    renderApp(container, a_id, a_name, isEditor, editorStyle);
  },
};

// Standalone Mode
const { a_id, a_name, isLocal } = getUrlParams();

if (isLocal) {
  const container = document.getElementById("root");

  if (container) {
    renderApp(container, a_id, a_name, false);
  } else {
    console.error("Standalone mode: No root container found.");
  }
}
