import React, { useState, useEffect } from "react";
import ImageSlideShow from "../../ImageSlideShow";

interface ImageTemplateProps {
  template: {
    images: { image_url: string; description: string }[];
    quote: { title: string; url: string };
  };
  closeSlider: () => void;
}

export default function ListViewWithQuoteTemplate({
  template,
  closeSlider,
}: ImageTemplateProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true); // State to control text opacity

  useEffect(() => {
    // Trigger fade-out before the description changes
    const timeout = setTimeout(() => setFade(false), 0);

    // After fade-out, change the description and fade it back in
    const fadeInTimeout = setTimeout(() => {
      setFade(true);
    }, 300); // Match this duration to your transition timing

    return () => {
      clearTimeout(timeout);
      clearTimeout(fadeInTimeout);
    };
  }, [currentIndex]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
        overflowY: "hidden",
      }}
    >
      <button className="slider-minimize-button" onClick={() => closeSlider()}>
        <i className="fa-solid fa-minus"></i>
      </button>

      <div style={{ position: "relative", height: "60%", overflow: "hidden" }}>
        <ImageSlideShow
          images={template.images}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </div>

      <div
        style={{
          height: "40%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: "1.1rem",
            margin: "1rem",
            textAlign: "left",
            opacity: fade ? 1 : 0, // Dynamically control opacity
            transition: "opacity 0.5s ease-in-out", // Smooth fade effect
          }}
        >
          {fade && template.images[currentIndex].description}
        </div>
        <div style={{ marginBottom: "3rem" }}>
          <a
            className="slider-cta-button"
            rel="noreferrer"
            href={template.quote.url}
            target="_blank"
          >
            {template.quote.title}
          </a>
        </div>
      </div>
    </div>
  );
}
