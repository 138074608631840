export default class ChatCache {
  private localStorageId: string = "drastik_chat_cache";

  public addMessage(message: any, threadId: any, assistantId: any) {
    let cache = this.getAssistant(assistantId);
    cache.messages.push(message);
    cache.threadId = threadId;

    this.set(assistantId, cache);
  }

  public getMessages(assistantId: any) {
    return this.getAssistant(assistantId)?.messages ?? [];
  }

  public getThreadId(assistantId: any) {
    return this.getAssistant(assistantId)?.threadId;
  }

  public remove(assistantId: any) {
    let cache = this.getAssistant(assistantId);
    cache.messages = [];
    cache.threadId = null;

    this.set(assistantId, cache);
  }

  private set(assistantId: any, entry: any) {
    let cache = this.get();
    cache[assistantId] = entry;
    localStorage.setItem(this.localStorageId, JSON.stringify(cache));
  }

  private getAssistant(assistantId: any) {
    let cache: any = localStorage.getItem(this.localStorageId);

    if (cache) {
      try {
        let oldCache = JSON.parse(cache);

        if (oldCache?.threadId) this.removeAll();
      } catch (e) {
        this.removeAll();
      }
    }

    return this.initializeAndGet(assistantId)[assistantId];
  }

  private get() {
    let cache: any = localStorage.getItem(this.localStorageId);
    return JSON.parse(cache);
  }

  private removeAll() {
    localStorage.removeItem(this.localStorageId);
  }

  private initializeAndGet(assistantId: any) {
    let cache: any = localStorage.getItem(this.localStorageId);

    if (!cache) localStorage.setItem(this.localStorageId, "{}");

    cache = localStorage.getItem(this.localStorageId);
    cache = JSON.parse(cache);

    if (!cache[assistantId])
      cache[assistantId] = { messages: [], threadId: null };

    localStorage.setItem(this.localStorageId, JSON.stringify(cache));

    return cache;
  }
}
