import Colors from "../../../../utils/Colors";

export default function HandoffButton({
  label,
  style,
  setHandoffFormFullscreen,
  show,
}: any) {
  return (
    <>
      {show && (
        <div className="shortcut-buttons">
          <button
            className="shortcut-button"
            style={{
              color: Colors.lightenHexColor(style.color, 20),
            }}
            onClick={() => setHandoffFormFullscreen(true)}
          >
            {label}
          </button>
        </div>
      )}
    </>
  );
}
